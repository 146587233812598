<template>
  <el-dialog
    v-model="shouldShowModal"
    class="crafting"
    :class="{ 'ru-locale': $i18n.locale === 'ru' }"
    :fullscreen="isMobile"
    :title="$t('warehouse')"
    :append-to-body="true"
    :center="true"
  >
    <div v-loading="loadingNft">
      <p class="crafting-description">{{ $t(props.description || '') }}</p>
      <StorageMobile
        v-if="($device.isMobile || $device.isTablet) && tokensData"
        :tokens="tokensData"
        :nft-tokens="nftTokens"
      />
      <StorageDesktop v-else-if="!$device.isMobile && tokensData" :tokens="tokensData" :nft-tokens="nftTokens" />
    </div>
  </el-dialog>
</template>
<script lang="ts" setup>
import { computed, watch } from 'vue';
import { apiUrls } from '~/utils/constants';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { ZeroAddress } from 'ethers';
import type { ITokenModel } from '~/types/apiService';
import type { BuildingProps } from '~/types/crafting';
import type { TNullable } from '~/types/common';
import type { TokenDescription } from '~/types/token';
import { tokensConfig } from '~/utils/constants';
import type { ContractAddresses, ContractAddressesItem } from '~/types/contractAddresses';
const { apiUrl, blockchain } = useEnvs();
const { isMobile } = useDevice();
const { address } = useWeb3ModalAccount();
const { getContractReadOnly } = useAbiAccess();
const nftTokens = ref<TokenDescription[]>([] as TokenDescription[]);
const loadingNft = ref(false);

const props = defineProps<{
  selectedBuilding: TNullable<BuildingProps>;
  isStorageSelected: Boolean;
  description: string;
}>();

const { data: tokensData, refresh: refreshTokens } = useFetch<ITokenModel[]>(apiUrls.token.tokens, {
  baseURL: apiUrl,
  transform: (data) => {
    return data.sort((a, b) => a.displayOrder - b.displayOrder);
  }
});

const getNftData = async () => {
  loadingNft.value = true;
  const erc721Tokens = Object.entries(tokensConfig)
    .filter(([, value]) => value.interface === 'erc721')
    .map(([key, value]) => ({ token: key, ...value }));

  const allNftIdsPromises = erc721Tokens.map(async (tokenInfo) => {
    const tokenName = tokenInfo.token as unknown as keyof ContractAddresses;
    const tokenAddress = (blockchain.contracts[tokenName] as ContractAddressesItem).addresses.contract;
    const contract = await getContractReadOnly('erc721', tokenAddress);
    const nftTokenIds = await contract.getTokenIds(address.value || ZeroAddress);
    const nftUriArray = await Promise.all(nftTokenIds.map((id: string) => contract.tokenURI(id)));
    return nftUriArray;
  });
  const allNftUris = (await Promise.all(allNftIdsPromises)).flat();
  const nftMetadataArray = await Promise.all(allNftUris.map((uri: string) => $fetch(uri)));

  nftTokens.value = nftMetadataArray.map((nft: any) => {
    const { image_url, name } = nft;
    return {
      name,
      imageUrl: image_url,
      description: `${name}Description`
    };
  });

  loadingNft.value = false;
};

watch(
  [address],
  async () => {
    await getNftData();

    await refreshTokens();
  },
  {
    immediate: true
  }
);

const shouldShowModal = computed(() => !!(props.isStorageSelected && tokensData?.value));
</script>
<style lang="scss" scoped></style>
