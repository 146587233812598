import { apiUrls } from '~/utils/constants';
import type { SellerItem } from '~/types/seller';
import type { WebSocketStatus } from '@vueuse/core';

interface IUseStreetMerchant {
  data: Ref<SellerItem[]>;
  status: Ref<WebSocketStatus>;
}

export default (): IUseStreetMerchant => {
  const data = ref<SellerItem[]>([]);
  const status = ref<WebSocketStatus>('CLOSED');

  if (!import.meta.client) {
    console.log(`WebSocket prevent on server side - streetMerchant`);

    return {
      data,
      status
    };
  }

  const { wsUrl } = useEnvs();
  const { status: wsStatus } = useWebSocket(`${wsUrl}${apiUrls.ws.streetMerchant}`, {
    autoReconnect: {
      retries: 3,
      delay: 2000,
      onFailed() {
        console.log(`WebSocket connection failed - streetMerchant`);
      }
    },
    onConnected() {
      console.log(`WebSocket connected - streetMerchant`);
    },
    onMessage(ws: WebSocket, event: MessageEvent) {
      const parsedData = JSON.parse(event.data);

      if (!parsedData.length) {
        return;
      }

      data.value = parsedData;
      status.value = wsStatus.value;

      console.log(`WebSocket new message - streetMerchant`);
    }
  });

  return {
    data,
    status
  };
};
